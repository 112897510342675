<template>
  <svg viewBox="0 0 60 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.0697 16.0986C31.4274 16.0986 32.5281 14.942 32.5281 13.5152C32.5281 12.0885 31.4274 10.9319 30.0697 10.9319C28.712 10.9319 27.6114 12.0885 27.6114 13.5152C27.6114 14.942 28.712 16.0986 30.0697 16.0986Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.0697 34.1819C31.4274 34.1819 32.5281 33.0253 32.5281 31.5986C32.5281 30.1718 31.4274 29.0152 30.0697 29.0152C28.712 29.0152 27.6114 30.1718 27.6114 31.5986C27.6114 33.0253 28.712 34.1819 30.0697 34.1819Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.0697 52.2652C31.4274 52.2652 32.5281 51.1086 32.5281 49.6819C32.5281 48.2552 31.4274 47.0986 30.0697 47.0986C28.712 47.0986 27.6114 48.2552 27.6114 49.6819C27.6114 51.1086 28.712 52.2652 30.0697 52.2652Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M47.2781 16.0986C48.6358 16.0986 49.7364 14.942 49.7364 13.5152C49.7364 12.0885 48.6358 10.9319 47.2781 10.9319C45.9204 10.9319 44.8197 12.0885 44.8197 13.5152C44.8197 14.942 45.9204 16.0986 47.2781 16.0986Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M47.2781 34.1819C48.6358 34.1819 49.7364 33.0253 49.7364 31.5986C49.7364 30.1718 48.6358 29.0152 47.2781 29.0152C45.9204 29.0152 44.8197 30.1718 44.8197 31.5986C44.8197 33.0253 45.9204 34.1819 47.2781 34.1819Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M47.2781 52.2652C48.6358 52.2652 49.7364 51.1086 49.7364 49.6819C49.7364 48.2552 48.6358 47.0986 47.2781 47.0986C45.9204 47.0986 44.8197 48.2552 44.8197 49.6819C44.8197 51.1086 45.9204 52.2652 47.2781 52.2652Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8614 16.0986C14.2191 16.0986 15.3197 14.942 15.3197 13.5152C15.3197 12.0885 14.2191 10.9319 12.8614 10.9319C11.5037 10.9319 10.4031 12.0885 10.4031 13.5152C10.4031 14.942 11.5037 16.0986 12.8614 16.0986Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8614 34.1819C14.2191 34.1819 15.3197 33.0253 15.3197 31.5986C15.3197 30.1718 14.2191 29.0152 12.8614 29.0152C11.5037 29.0152 10.4031 30.1718 10.4031 31.5986C10.4031 33.0253 11.5037 34.1819 12.8614 34.1819Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8614 52.2652C14.2191 52.2652 15.3197 51.1086 15.3197 49.6819C15.3197 48.2552 14.2191 47.0986 12.8614 47.0986C11.5037 47.0986 10.4031 48.2552 10.4031 49.6819C10.4031 51.1086 11.5037 52.2652 12.8614 52.2652Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDotsGridEmpty'
}
</script>
